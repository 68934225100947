import { DataLayerEvent, EventValue } from 'types'
import Flat, { Unit } from 'types/shared/flat'

import { filterOutObject } from './filterOutNulls'

type FlatType = Flat | Flat[] | Unit | Unit[] | null

const getValue = (flat: FlatType, key: keyof (Flat | Unit)): EventValue => {
  if (!flat) return undefined

  if (Array.isArray(flat)) {
    return flat
      .map((item) => item[key])
      .filter((item) => item)
      .join(', ')
  }

  return flat[key] ?? undefined
}

const emitGTMEvent = (event: DataLayerEvent, flat?: FlatType) => {
  if (typeof window !== `undefined` && window.dataLayer) {
    const GTMEvent = event
    GTMEvent.url = GTMEvent.url || window.location.href
    if (flat) {
      const values = {
        id: getValue(flat, 'id'),
        building: getValue(flat, 'building'),
        invest: getValue(flat, 'investment'),
        floor: getValue(flat, 'floor'),
        rooms: getValue(flat, 'rooms'),
        additional: getValue(flat, 'additionalArea'),
        size: getValue(flat, 'area'),
        number: getValue(flat, 'number'),
        'estate-id': getValue(flat, 'investmentID'),
      }
      if (values.id) GTMEvent.id = values.id
      if (values.building) GTMEvent.building = values.building
      if (values.invest) GTMEvent.invest = values.invest
      if (values.floor) GTMEvent.floor = values.floor
      if (values.rooms) GTMEvent.rooms = values.rooms
      if (values.additional) GTMEvent.additional = values.additional
      if (values.size) GTMEvent.size = values.size
      if (values.number) GTMEvent.number = values.number
      if (values['estate-id']) GTMEvent['estate-id'] = values['estate-id']
    }
    window.dataLayer.push(filterOutObject(GTMEvent) as DataLayerEvent)
  }
}

export default emitGTMEvent
