import { Districts, Investments, Cities } from 'types/layout/flatBrowser'
import { useMemo } from 'react'
import {
  getAltInvestmentName,
  getInvestmentFamilyID,
  getInvestmentID,
} from 'utils/getInvestmentData'
import { generateQueryFilters } from 'crm/queries/flats'
import useFilters from './useFilters'
import useFlats from './useFlats'

type Props = {
  investment?: string
  investmentFamily?: Investments
  hiddenInvestmentIDs?: ReturnType<typeof getInvestmentID>[]
  building?: string
  district?: Districts | Districts[]
  city?: Cities
  onlyAvailable?: boolean
}

const useDefaultFilters = ({
  city,
  district,
  investment,
  investmentFamily,
  hiddenInvestmentIDs,
  building,
  onlyAvailable = true,
}: Props) => {
  const altInvestment = useMemo(
    () =>
      investmentFamily
        ? getAltInvestmentName(investmentFamily, investment)
        : null,
    [investmentFamily, investment]
  )
  const investmentID = useMemo(
    () =>
      altInvestment
        ? getInvestmentID(investment ?? '') || getInvestmentID(altInvestment)
        : null,
    [altInvestment]
  )
  const investmentFamilyID = useMemo(
    () =>
      altInvestment
        ? getInvestmentFamilyID(building ?? '') ||
          getInvestmentFamilyID(altInvestment)
        : null,
    [altInvestment, building]
  )

  const filters = generateQueryFilters({
    onlyAvailable,
    district,
    building,
    investmentFamilyID,
    investmentID,
  })

  const { flats, loading } = useFlats({
    filters,
  })

  const serializedDistrict = Array.isArray(district)
    ? district.join(',')
    : district

  const stringifiedFlats = JSON.stringify(flats)

  const allFlats = useMemo(() => {
    return flats
      .filter(
        (el) =>
          !hiddenInvestmentIDs ||
          !hiddenInvestmentIDs.includes(el.investmentID!)
      )
      .filter((el) => !investmentFamily || investmentFamily === el.familyName)
      .filter((el) => !investment || investment === el.investment)
      .filter((el) => !building || building === el.building)
      .filter((el) => !city || city === el.city)
      .filter((el) => !district || serializedDistrict?.includes(el.district))
  }, [
    hiddenInvestmentIDs,
    investment,
    investmentFamily,
    building,
    stringifiedFlats,
    serializedDistrict,
    city,
  ])

  const { defaultFilters } = useFilters(allFlats, {
    floor: true,
    rooms: true,
    area: true,
    price: true,
    locations: true,
    stage: true,
    isMezzanine: true,
    isDuplexApartment: true,
    gardenArea: true,
  })

  return { filters: defaultFilters, loading }
}

export default useDefaultFilters
