/* eslint-disable import/prefer-default-export */
import { DataLayerEvent } from 'types'
import { Flat, Unit } from 'types/shared/flat'
import {
  getInvestmentCity,
  getInvestmentGroup,
  isInvestmentFamily,
} from './getInvestmentData'
import isSSR from './isSSR'

// type PageType = ReturnType<typeof getPageType>

const addToDataLayer = (data: DataLayerEvent) => {
  if (isSSR) return

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

const getPageType = (url: string) => {
  if (url.includes('kredyt')) return 'Kredyt'
  if (url.includes('oferty-specjalne')) return 'Oferty specjalne'
  if (url.includes('gotowe-do-odbioru')) return 'Gotowe do odbioru'
  if (url.includes('dziennik-budowy')) return 'Dziennik budowy'
  if (url.includes('poznaj-nas')) return 'Poznaj nas'
  if (url.includes('dobrze-zaplanowane')) return 'Dobrze zaplanowane'
  if (url.includes('inwestycje-zrealizowane')) return 'Inwestycje zrealizowane'

  if (url.includes('biura-sprzedazy')) return 'Biura sprzedazy'
  if (url.includes('obsluga-posprzedazowa')) return 'Obsluga posprzedazowa'
  if (url.includes('serwis-techniczny')) return 'Serwis techniczny'
  if (url.includes('siedziba-firmy')) return 'Siedziba firmy'
  if (url.includes('iod')) return 'IOD'
  if (url.includes('kontakt')) return 'Kontakt'

  if (url.includes('ulubione')) return 'Ulubione'

  if (
    Boolean(url.match(/\/warszawa\/.*?\/[0-9]/)) ||
    Boolean(url.match(/\/krakow\/.*?\/[0-9]/))
  )
    return 'Mieszkanie'

  if (url.includes('warszawa')) {
    const regex = url.match(/(\/warszawa\/)(.*?)\/(.*?)/)
    if (regex && isInvestmentFamily(regex[2])) return 'Rodzina Inwestycji'
    if (regex) return 'Inwestycja'
    return 'Warszawa'
  }
  if (url.includes('krakow')) {
    const regex = url.match(/(\/krakow\/)(.*?)\/(.*?)/)
    if (regex && isInvestmentFamily(regex[2])) return 'Rodzina Inwestycji'
    if (regex) return 'Inwestycja'
    return 'Krakow'
  }

  return 'Strona główna'
}

const generateFlatInfo = (
  flat: Flat | Unit
): Omit<DataLayerEvent, 'event'> => ({
  investment_city: getInvestmentCity(flat.investmentID),
  investment_family_name: getInvestmentGroup(flat.investment) as string,
  investment_single_name: flat.investment,

  // apartment_investment: flat.investment
  apartment_name: `Mieszkanie nr ${flat.number}`,
  apartment_number: flat.number,
  apartment_type: `${flat.rooms}-pokojowe`,
  apartment_floor: flat.floor,
  apartment_area: flat.area,
  apartment_rooms: flat.rooms,
  apartment_garden: `${Boolean(flat.gardenArea)}`,
  apartment_balcony: `${Boolean(flat.balconyArea)}`,
})

export const dataLayerPage = (url: string) =>
  addToDataLayer({
    event: 'virtual_page_view',
    page_type: getPageType(url),
  })

export const dataLayerFlatPage = (flat: Flat | Unit) =>
  // @ts-ignore
  addToDataLayer({
    // event: 'pageview',
    ...generateFlatInfo(flat),
  })

export const dataLayerFavourites = (flat: Flat | Unit) =>
  addToDataLayer({
    event: 'add_to_wishlist',
    ...generateFlatInfo(flat),
  })

export const dataLayerApartmentPlan = (
  flat: Flat | Unit,
  type: '2D' | '3D' | '360'
) =>
  addToDataLayer({
    event: 'view_apartment_plan',
    ...generateFlatInfo(flat),
    plan_type: type,
  })

export const dataLayerVirtualTour = (flat: Flat | Unit) =>
  addToDataLayer({
    event: 'click_button',
    button_name: 'Virtual Tour',
    ...generateFlatInfo(flat),
  })

export const dataLayerDownload = (
  flat: Flat | Unit,
  filename: string,
  url: string
) =>
  addToDataLayer({
    event: 'download',
    download_file: filename,
    download_from: url,
    ...generateFlatInfo(flat),
  })

export const dataLayerPromotion = (name: string) =>
  addToDataLayer({
    event: 'click_promotion',
    promotion_name: name,
  })

export const dataLayerForm = (
  formType: 'Contact' | 'Lead',
  formName:
    | 'Ask for an apartment'
    | 'Ask for credit'
    | 'Ask about a premises'
    | 'After-sales services'
    | 'Technical customer services',
  lead_id?: string
) =>
  lead_id
    ? addToDataLayer({
        event: 'form_submitted',
        form_type: formType,
        form_name: formName,
        lead: 'true',
        lead_id,
      })
    : addToDataLayer({
        event: 'form_submitted',
        form_type: formType,
        form_name: formName,
        lead: 'false',
      })
