import { useState, useEffect, useMemo } from 'react'

import scrollToSection from 'utils/scrollToSection'

import type {
  Filters,
  FiltersToInclude,
  Investments,
  RangeFilter,
} from 'types/layout/flatBrowser'
import type { Flat, Unit } from 'types/shared/flat'
import { sortAsNumbers, sortString } from 'utils/sorting'

const createLocation = (city: string, district: string) =>
  `${city} - ${district}`

const useFilters = (
  flats: Flat[] | Unit[],
  includedFilters: FiltersToInclude = {}
) => {
  const [defaultFilters, setDefaultFilters] = useState<Filters>({
    floor: undefined,
    rooms: undefined,
    area: undefined,
    price: undefined,
    stage: undefined,
    investments: undefined,
    locations: undefined,
    status: undefined,
    isMezzanine: false,
    isDuplexApartment: false,
    gardenArea: undefined,
  })

  const [temporaryFilters, setTemporaryFilters] = useState<Filters>({
    floor: undefined,
    rooms: undefined,
    area: undefined,
    price: undefined,
    stage: undefined,
    investments: undefined,
    locations: undefined,
    status: undefined,
    isMezzanine: false,
    isDuplexApartment: false,
    gardenArea: undefined,
  })

  const [filters, setFilters] = useState<Filters>({
    floor: undefined,
    rooms: undefined,
    area: undefined,
    price: undefined,
    stage: undefined,
    investments: undefined,
    locations: undefined,
    status: undefined,
    isMezzanine: false,
    isDuplexApartment: false,
    gardenArea: undefined,
  })

  const applyDefaultFilters = () => {
    const uniqueFloors = [
      ...new Set(
        flats
          .filter(({ floor }) => floor !== null && floor !== undefined)
          .map(({ floor }) => floor)
          .sort(sortAsNumbers())
      ),
    ]

    const uniqueRooms = [
      ...new Set(
        flats
          .filter(({ rooms }) => rooms !== null && rooms !== undefined)
          .map(({ rooms }) => rooms)
          .sort(sortAsNumbers())
      ),
    ]

    const uniqueStages = [
      ...new Set(
        flats
          .filter(({ stage }) => stage !== null && stage !== undefined)
          .map(({ stage }) => stage)
          .sort(sortAsNumbers())
      ),
    ]

    const uniqueInvestments = [
      ...new Set(
        flats
          .filter(
            ({ investment }) => investment !== null && investment !== undefined
          )
          .map(({ investment }) => investment)
          .sort(sortString())
      ),
    ] as Investments[]

    const uniqueLocations = [
      ...new Set(
        (flats as Flat[])
          .filter(
            ({ city, district }) =>
              city !== null &&
              city !== undefined &&
              district !== null &&
              district !== undefined
          )
          .map(({ city, district }) => createLocation(city, district))
          .sort(sortString())
      ),
    ]

    const minArea = Math.min(...flats.map(({ area }) => Math.floor(area)))
    const maxArea = Math.max(...flats.map(({ area }) => Math.ceil(area)))

    const minGardenArea = Math.min(
      ...flats.map(({ gardenArea }) => Math.floor(gardenArea))
    )
    const maxGardenArea = Math.max(
      ...flats.map(({ gardenArea }) => Math.ceil(gardenArea))
    )

    const minPrice = Math.min(...flats.map(({ price }) => Math.floor(price)))
    const maxPrice = Math.max(...flats.map(({ price }) => Math.ceil(price)))

    const savedArea: RangeFilter | null = JSON.parse(
      sessionStorage.getItem('FILTERS_AREA') ?? 'null'
    )
    const savedGardenArea: RangeFilter | null = JSON.parse(
      sessionStorage.getItem('FILTERS_GARDEN_AREA') ?? 'null'
    )
    const savedPrice: RangeFilter | null = JSON.parse(
      sessionStorage.getItem('FILTERS_PRICE') ?? 'null'
    )
    const savedRooms: number[] | null = JSON.parse(
      sessionStorage.getItem('FILTERS_ROOMS') ?? 'null'
    )
    const savedFloors: number[] | null = JSON.parse(
      sessionStorage.getItem('FILTERS_FLOORS') ?? 'null'
    )
    const savedStages: number[] | null = JSON.parse(
      (flats[0] &&
        sessionStorage.getItem(`FILTERS_STAGE_${flats[0].investment}`)) ??
        'null'
    )
    const savedStatus: Filters['status'] = JSON.parse(
      sessionStorage.getItem('FILTERS_STATUS') ?? 'null'
    )
    const savedInvestments: Filters['investments'] = JSON.parse(
      sessionStorage.getItem('FILTERS_INVESTMENTS') ?? 'null'
    )
    const savedLocations: Filters['locations'] = JSON.parse(
      sessionStorage.getItem('FILTERS_LOCATIONS') ?? 'null'
    )

    const savedIsMezzanine: Filters['isMezzanine'] = JSON.parse(
      sessionStorage.getItem('FILTERS_IS_MEZZANINE') ?? 'null'
    )

    const savedIsDuplexApartment: Filters['isDuplexApartment'] = JSON.parse(
      sessionStorage.getItem('FILTERS_IS_DUPLEX_APARTMENT') ?? 'null'
    )

    const initialFilters: Filters = {
      floor: uniqueFloors,
      stage: uniqueStages,
      rooms: uniqueRooms,
      investments: uniqueInvestments,
      locations: uniqueLocations,
      price: {
        from: minPrice,
        to: maxPrice,
      },
      area: {
        from: minArea,
        to: maxArea,
      },
      status: null,
      isMezzanine: false,
      isDuplexApartment: false,
      gardenArea: {
        from: minGardenArea,
        to: maxGardenArea,
      },
    }

    setDefaultFilters(initialFilters)

    const savedFilters: Filters = {
      floor: uniqueFloors,
      stage: uniqueStages,
      rooms: uniqueRooms,
      investments: uniqueInvestments,
      locations: uniqueLocations,
      price: {
        from: minPrice,
        to: maxPrice,
      },
      area: {
        from: minArea,
        to: maxArea,
      },
      status: null,
      isMezzanine: false,
      isDuplexApartment: false,
      gardenArea: {
        from: minGardenArea,
        to: maxGardenArea,
      },
    }

    if (savedArea) {
      const newArea = savedArea
      if (savedArea.from <= minArea || savedArea.from >= maxArea) {
        newArea.from = minArea
      }
      if (savedArea.to >= maxArea || savedArea.to <= minArea) {
        newArea.to = maxArea
      }
      savedFilters.area = newArea
    }
    if (savedGardenArea) {
      const newGardenArea = savedGardenArea
      if (
        savedGardenArea.from <= minGardenArea ||
        savedGardenArea.from >= maxGardenArea
      ) {
        newGardenArea.from = minGardenArea
      }
      if (
        savedGardenArea.to >= maxGardenArea ||
        savedGardenArea.to <= minGardenArea
      ) {
        newGardenArea.to = maxGardenArea
      }
      savedFilters.gardenArea = newGardenArea
    }
    if (savedPrice) {
      const newPrice = savedPrice
      if (savedPrice.from <= minPrice || savedPrice.from >= maxPrice) {
        newPrice.from = minPrice
      }
      if (savedPrice.to >= maxPrice || savedPrice.to <= minPrice) {
        newPrice.to = maxPrice
      }
      savedFilters.price = newPrice
    }

    savedFilters.rooms = (savedRooms || [])
      .filter((savedRoom) => uniqueRooms.includes(savedRoom))
      .sort(sortAsNumbers())

    savedFilters.floor = (savedFloors || [])
      .filter((savedFloor) => uniqueFloors.includes(savedFloor))
      .sort(sortAsNumbers())

    savedFilters.stage = (savedStages || [])
      .filter((savedStage) => uniqueStages.includes(savedStage))
      .sort(sortAsNumbers())

    savedFilters.investments = (savedInvestments || [])
      .filter((savedInvestment) => uniqueInvestments.includes(savedInvestment))
      .sort(sortString())

    savedFilters.locations = (savedLocations || [])
      .filter((savedLocation) => uniqueLocations.includes(savedLocation))
      .sort(sortString())

    savedFilters.status = savedStatus ?? null

    savedFilters.isMezzanine = savedIsMezzanine

    savedFilters.isDuplexApartment = savedIsDuplexApartment

    setTemporaryFilters(savedFilters)
    setFilters(savedFilters)
  }

  const applyFilters = (newFilters: Filters = temporaryFilters) => {
    setFilters(newFilters)

    scrollToSection('#browser-content')

    sessionStorage.setItem('FILTERS_AREA', JSON.stringify(newFilters.area))
    sessionStorage.setItem(
      'FILTERS_GARDEN_AREA',
      JSON.stringify(newFilters.gardenArea)
    )
    sessionStorage.setItem('FILTERS_PRICE', JSON.stringify(newFilters.price))
    sessionStorage.setItem('FILTERS_ROOMS', JSON.stringify(newFilters.rooms))
    sessionStorage.setItem('FILTERS_FLOORS', JSON.stringify(newFilters.floor))
    sessionStorage.setItem('FILTERS_STATUS', JSON.stringify(newFilters.status))
    sessionStorage.setItem(
      'FILTERS_IS_MEZZANINE',
      JSON.stringify(newFilters.isMezzanine)
    )
    sessionStorage.setItem(
      'FILTERS_IS_DUPLEX_APARTMENT',
      JSON.stringify(newFilters.isDuplexApartment)
    )
    sessionStorage.setItem(
      'FILTERS_INVESTMENTS',
      JSON.stringify(newFilters.investments)
    )
    sessionStorage.setItem(
      'FILTERS_LOCATIONS',
      JSON.stringify(newFilters.locations)
    )
    if (flats[0]) {
      sessionStorage.setItem(
        `FILTERS_STAGE_${flats[0].investment}`,
        JSON.stringify(newFilters.stage)
      )
    }
  }

  const clearFilters = () => {
    applyFilters({ ...defaultFilters, rooms: [], floor: [], stage: [] })
  }

  const handleFloor = (floorNumber: number, update: boolean = false) => {
    const { floor } = temporaryFilters
    if (floor && floor.includes(floorNumber)) {
      const newFilters = {
        ...temporaryFilters,
        floor: floor
          .filter((item) => item !== floorNumber)
          .sort(sortAsNumbers()),
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    } else if (floor) {
      const newFilters = {
        ...temporaryFilters,
        floor: [...floor, floorNumber].sort(sortAsNumbers()),
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    }
  }

  const handleStage = (stageNumber: number, update: boolean = false) => {
    const { stage } = temporaryFilters

    if (stage && stage.includes(stageNumber)) {
      const newFilters = {
        ...temporaryFilters,
        stage: stage
          .filter((item) => item !== stageNumber)
          .sort(sortAsNumbers()),
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    } else if (stage) {
      const newFilters = {
        ...temporaryFilters,
        stage: [...stage, stageNumber].sort(sortAsNumbers()),
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    }
  }

  const handleInvestment = (
    investment: Investments,
    update: boolean = false
  ) => {
    const { investments } = temporaryFilters

    if (investments && investments.includes(investment)) {
      const newFilters = {
        ...temporaryFilters,
        investments: investments
          .filter((item) => item !== investment)
          .sort(sortString()),
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    } else if (investments) {
      const newFilters = {
        ...temporaryFilters,
        investments: [...investments, investment].sort(sortString()),
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    }
  }

  const handleLocation = (
    city: string,
    district: string,
    update: boolean = false
  ) => {
    const { locations } = temporaryFilters
    const location = createLocation(city, district)

    if (locations && locations.includes(location)) {
      const newFilters = {
        ...temporaryFilters,
        locations: locations
          .filter((item) => item !== location)
          .sort(sortString()),
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    } else if (locations) {
      const newFilters = {
        ...temporaryFilters,
        locations: [...locations, location].sort(sortString()),
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    }
  }

  const handleRooms = (roomsNumber: number, update: boolean = false) => {
    const { rooms } = temporaryFilters

    if (rooms && rooms.includes(roomsNumber)) {
      const newFilters = {
        ...temporaryFilters,
        rooms: rooms.filter((item) => item !== roomsNumber),
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    } else if (rooms) {
      const newFilters = {
        ...temporaryFilters,
        rooms: [...rooms, roomsNumber],
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    }
    if (update) {
      applyFilters()
    }
  }

  const handleStatus = (status: Filters['status'], update: boolean = false) => {
    const newFilters = {
      ...temporaryFilters,
      status,
    }
    setTemporaryFilters(newFilters)
    if (update) {
      setFilters(newFilters)
    }
  }

  const handlePrice = (value: Filters['price'], update: boolean = false) => {
    if (value) {
      const newFilters = {
        ...temporaryFilters,
        price: {
          ...value,
        },
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    }
  }

  const handleArea = (value: Filters['area'], update: boolean = false) => {
    if (value) {
      const newFilters = {
        ...temporaryFilters,
        area: {
          ...value,
        },
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    }
  }

  const handleGardenArea = (
    value: Filters['gardenArea'],
    update: boolean = false
  ) => {
    if (value) {
      const newFilters = {
        ...temporaryFilters,
        gardenArea: {
          ...value,
        },
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    }
  }

  const handleMezzanine = (
    value: Filters['isMezzanine'],
    update: boolean = false
  ) => {
    if (value !== undefined) {
      const newFilters = {
        ...temporaryFilters,
        isMezzanine: value,
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    }
  }

  const handleDuplexApartment = (
    value: Filters['isDuplexApartment'],
    update: boolean = false
  ) => {
    if (value !== undefined) {
      const newFilters = {
        ...temporaryFilters,
        isDuplexApartment: value,
      }
      setTemporaryFilters(newFilters)
      if (update) {
        setFilters(newFilters)
      }
    }
  }

  const filteredFlats = useMemo(
    () =>
      [...flats].filter((flat) => {
        let approved = true
        if (
          !!includedFilters.area &&
          filters.area &&
          (flat.area < filters.area.from || filters.area.to < flat.area)
        ) {
          approved = false
        }

        if (
          !!includedFilters.gardenArea &&
          filters.gardenArea &&
          (flat.gardenArea < filters.gardenArea.from ||
            filters.gardenArea.to < flat.gardenArea)
        ) {
          approved = false
        }

        if (
          !!includedFilters.price &&
          filters.price &&
          (flat.price < filters.price.from || filters.price.to < flat.price)
        ) {
          approved = false
        }

        if (
          !!includedFilters.rooms &&
          filters.rooms &&
          filters.rooms.length &&
          !filters.rooms.includes(flat.rooms)
        ) {
          approved = false
        }

        if (
          !!includedFilters.floor &&
          filters.floor &&
          filters.floor.length &&
          !filters.floor.includes(flat.floor)
        ) {
          approved = false
        }

        if (
          !!includedFilters.stage &&
          filters.stage &&
          filters.stage.length &&
          !filters.stage.includes(flat.stage)
        ) {
          approved = false
        }

        if (
          !!includedFilters.investments &&
          filters.investments &&
          filters.investments.length &&
          !filters.investments.includes(flat.investment as Investments)
        ) {
          approved = false
        }

        if (
          !!includedFilters.locations &&
          (flat as Flat).city &&
          (flat as Flat).district &&
          filters.locations &&
          filters.locations.length &&
          !filters.locations.includes(
            createLocation((flat as Flat).city, (flat as Flat).district)
          )
        ) {
          approved = false
        } else if (
          !!includedFilters.locations &&
          (!(flat as Flat).city || !(flat as Flat).district)
        ) {
          approved = false
        }

        if (
          !!includedFilters.status &&
          filters.status &&
          flat.status !== filters.status
        ) {
          approved = false
        }

        if (
          !!includedFilters.isMezzanine &&
          filters.isMezzanine &&
          flat.isMezzanine !== filters.isMezzanine
        ) {
          approved = false
        }

        if (
          !!includedFilters.isDuplexApartment &&
          filters.isDuplexApartment &&
          flat.isDuplexApartment !== filters.isDuplexApartment
        ) {
          approved = false
        }

        return approved
      }),
    [filters, includedFilters]
  )

  useEffect(() => {
    applyDefaultFilters()
  }, [flats])

  useEffect(() => {
    setTemporaryFilters(filters)
  }, [filters])

  return {
    handleFloor,
    handleInvestment,
    handleLocation,
    handleStage,
    handleRooms,
    handleArea,
    handlePrice,
    handleStatus,
    handleMezzanine,
    handleDuplexApartment,
    handleGardenArea,
    applyFilters,
    clearFilters,
    filters,
    temporaryFilters,
    defaultFilters,
    filteredFlats,
  }
}

export default useFilters
