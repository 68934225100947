export const scrollTo = (top: number, behavior: ScrollBehavior = 'smooth') => {
  if (typeof window !== `undefined`) {
    window.scrollTo({
      top,
      behavior,
    })
  }
}

export const scrollToElement = (
  element: Element | null,
  behavior: ScrollBehavior = 'smooth'
) => {
  if (typeof window !== `undefined` && element) {
    const waitForScrollEnd = () => {
      let lastChangedFrame = 0
      let lastX = window.scrollX
      let lastY = window.scrollY

      return new Promise<boolean>((resolve) => {
        function tick(frames: number) {
          if (frames >= 200 || frames - lastChangedFrame > 15) {
            resolve(true)
          } else {
            if (window.scrollX !== lastX || window.scrollY !== lastY) {
              lastChangedFrame = frames
              lastX = window.scrollX
              lastY = window.scrollY
            }
            requestAnimationFrame(tick.bind(null, frames + 1))
          }
        }
        tick(0)
      })
    }

    const scrollThroughLazyLoad = () => {
      const navigation = document.getElementById('navigation')
      const elementPosition = (element as HTMLElement).offsetTop

      if (navigation) {
        const navigationOffset = navigation.offsetHeight
        const offsetPosition = elementPosition - navigationOffset
        const isScrollingUp = window.scrollY > elementPosition
        if (offsetPosition < 0) {
          element.scrollIntoView({ behavior })
        } else {
          window.scrollTo({
            top: isScrollingUp ? offsetPosition : elementPosition,
            behavior,
          })
        }
      } else {
        element.scrollIntoView({ behavior })
      }
      waitForScrollEnd().then(() => {
        if (
          Math.round(window.scrollY) <
          Math.round((element as HTMLElement).offsetTop)
        ) {
          scrollThroughLazyLoad()
        }
      })
    }
    scrollThroughLazyLoad()
    return true
  }
  return false
}

export const scrollToNextSibling = (parent: string) => {
  const origin = document.querySelector(parent)
  if (origin) {
    const sibling = origin.nextSibling
    if (sibling) {
      return scrollToElement(sibling as Element)
    }
  }
  return false
}

export const scrollTop = () => {
  if (typeof window !== `undefined`) {
    const topElement = document.querySelector('body')
    return scrollToElement(topElement)
  }
  return false
}

export const scrollToSection = (
  selector: string,
  behavior: ScrollBehavior = 'smooth',
  forceIntoView: boolean = false
) => {
  if (typeof window !== `undefined`) {
    const element = document.querySelector(selector)
    if (forceIntoView && element) {
      element.scrollIntoView({ behavior })
      return true
    }
    return scrollToElement(element, behavior)
  }
  return false
}

export const scrollToSectionTop = (selector: string) => {
  if (typeof window !== `undefined`) {
    const element = document.querySelector(selector)

    if (element) {
      const { top } = element.getBoundingClientRect()
      if (top < 0) {
        return scrollToElement(element)
      }
    }
  }
  return false
}

export default scrollToSection
