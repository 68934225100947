import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { LocalizedLink } from 'gatsby-theme-i18n'

import media from 'styles/media'

import Button from 'components/shared/Button'
import Container from 'components/shared/Container'
import { Heading, Text } from 'components/shared/Typography'
import LazyImage from 'components/shared/LazyImage'

import useBreakpoint from 'hooks/useBreakpoint'
import useLocation from 'hooks/useLocation'

import { scrollToNextSibling, scrollToSection } from 'utils/scrollToSection'

import type { ActiveRequired } from 'types/shared/active'
import type { HeaderSlide } from 'types/header'
import { Colors } from 'types/theme'

type HeaderSlideProps = HeaderSlide &
  ActiveRequired & {
    loading?: 'lazy' | 'eager'
    narrow?: boolean
    visible?: boolean
    withMap?: boolean
    onClick?: () => void
  }

const HeaderVideo = styled.video.attrs({ muted: true })`
  position: absolute !important;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
  object-fit: cover;
  ${media.sm.max} {
    object-position: 0 100%;
  }
`

const HeaderImage = styled(LazyImage)`
  position: absolute !important;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.2);
  }
`

export const HeaderContent = styled(Container).attrs({ slim: true })<
  ActiveRequired & { short: boolean }
>`
  position: relative;
  z-index: 3;

  opacity: 0;
  transform: translate3d(0, -6px, 0);

  padding-left: ${({ short }) => (short ? '80px' : '40px')};
  ${media.lg.min} {
    @media (max-width: 1800px) {
      padding-left: ${({ short }) => (short ? '70px' : '40px')};
    }
  }
  @media (min-width: 1280px) and (max-width: 1430px) {
    padding-left: ${({ active, short, theme }) =>
      !short || active ? theme.container.paddingMD : `70px`};
  }
  @media (max-width: 1279px) {
    margin-left: ${({ short }) => (short ? 'auto' : '0')};
  }
  /* ${media.lg.min} {
    padding-right: ${({ theme }) => `calc(${theme.header.mapWidth} / 2)`};
  } */
  ${media.lg.max} {
    padding-bottom: 50px;
  }
  ${media.sm.max} {
    padding-left: ${({ theme }) => theme.container.paddingMD};
  }
  ${media.lg.min} {
    padding-right: ${({ theme, active }) =>
      active
        ? `calc(${theme.header.mapWidth} / 2 + 2rem)`
        : `calc(${theme.header.mapWidth} / 3)`};
    h1 {
      max-width: 900px;
    }
    h2 {
      max-width: 1100px;
    }
  }
  ${Heading} {
    text-shadow: #000000 0px 0px 2px;
    span {
      color: ${({ theme }) => theme.colors.yellow};
      font-weight: 700;
      white-space: nowrap;
    }
  }

  transition: all 250ms ease-in-out; // TODO: find property and replace 'all'
`

const HeaderSlideWrapper = styled.div<{ withMap: boolean }>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.colors.dark};
  ${media.lg.min} {
    max-width: ${({ theme, withMap }) =>
      withMap ? `calc(100% - ${theme.header.mapWidth} / 2)` : '100%'};
  }
`

const HeaderSlideBadge = styled.div<{ themecolor?: keyof Colors }>`
  position: absolute;
  top: 2%;
  right: 2%;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 90px;
  height: 90px;
  background: ${({ theme, themecolor }) =>
    themecolor ? theme.colors[themecolor] : theme.colors.yellow};
  border-radius: 50%;

  ${media.lg.max} {
    width: 70px;
    height: 70px;

    ${Text} {
      font-size: 0.75rem;
    }
  }
`

const StyledHeading = styled(Heading)`
  margin-top: -1rem;
`

const Slide: React.FC<HeaderSlideProps> = ({
  backgroundImage,
  backgroundImageAlt,
  video,
  videoMobile,
  title,
  titleH,
  subtitle,
  link,
  linkText,
  active,
  visible,
  scroll,
  status,
  loading = 'lazy',
  narrow = true,
  withMap = false,
  videoOnMobile = true,
  onClick,
}) => {
  const [allowClick, setAllowClick] = useState(!video)
  const [currentVideo, setCurrentVideo] = useState(video)
  const hasLongTitle = title && title.length > 40
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const { t } = useTranslation('header')
  const { lg } = useBreakpoint()
  const pathname = useLocation()

  const videoVisible = !(!videoOnMobile && !lg)

  useEffect(() => {
    if (videoRef.current) {
      if (visible) {
        videoRef.current.autoplay = true
        videoRef.current.playsInline = true
        videoRef.current.muted = true
        videoRef.current.setAttribute('muted', '')
        videoRef.current.loop = true
        videoRef.current.controls = false
        setTimeout(() => (videoRef.current as HTMLVideoElement).play(), 0)
      } else {
        videoRef.current.pause()
      }
    }
  }, [visible, videoRef])

  useEffect(() => {
    if (videoRef.current) {
      const listener = () => setAllowClick(true)

      videoRef.current?.addEventListener('play', listener)
      return () => {
        videoRef.current?.removeEventListener('play', listener)
      }
    }
    return () => null
  }, [videoRef])

  useEffect(() => {
    if (videoRef.current) {
      const listener = () => setAllowClick(true)

      videoRef.current?.addEventListener('play', listener)
      return () => {
        videoRef.current?.removeEventListener('play', listener)
      }
    }
    return () => null
  }, [videoRef])

  useEffect(() => {
    setCurrentVideo(!lg && videoMobile ? videoMobile : video)
  }, [lg, video, videoMobile])

  const handleClick = () => {
    if (allowClick && onClick) {
      onClick()
    } else if (video && !allowClick) {
      videoRef.current?.play()
    }
  }

  return (
    <HeaderSlideWrapper onClick={handleClick} withMap={withMap}>
      {status === 'new' && (
        <HeaderSlideBadge themecolor="red">
          <Text
            transform="uppercase"
            themecolor="white"
            margin="0"
            weight={600}
            align="center"
          >
            Nowość
          </Text>
        </HeaderSlideBadge>
      )}
      {status === 'stage' && (
        <HeaderSlideBadge>
          <Text
            transform="uppercase"
            themecolor="dark"
            margin="0"
            weight={600}
            align="center"
          >
            Nowy
            <br /> Etap
          </Text>
        </HeaderSlideBadge>
      )}
      {status === 'soon' && (
        <HeaderSlideBadge>
          <Text
            transform="uppercase"
            themecolor="dark"
            margin="0"
            weight={600}
            align="center"
          >
            Już
            <br /> Wkrótce
          </Text>
        </HeaderSlideBadge>
      )}

      {visible && (
        <>
          {currentVideo && videoVisible ? (
            <HeaderVideo
              key={currentVideo.src}
              ref={videoRef}
              controls={false}
              autoPlay
              muted
              playsInline
              loop
            >
              <source src={currentVideo.src} type={currentVideo.type} />
            </HeaderVideo>
          ) : (
            <HeaderImage
              loading={loading}
              src={backgroundImage}
              alt={backgroundImageAlt ?? title ?? ''}
            />
          )}
        </>
      )}

      <HeaderContent short={!!narrow} active={active}>
        <Heading
          transform="uppercase"
          themecolor="white"
          dangerouslySetInnerHTML={{ __html: title ?? '' }}
          size={hasLongTitle ? 70 : 90}
          sizeDiff={0.85}
          as={titleH ?? 'h2'}
        />
        {subtitle && (
          <StyledHeading
            as={
              (titleH
                ? `h${Math.max(6, Number(titleH?.slice(1)) - 1)}`
                : 'h3') as unknown as undefined
            }
            size={50}
            sizeDiff={0.7}
            weight={500}
            margin="2rem"
            themecolor="white"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}

        {title && (
          <>
            {link ? (
              <Button as={LocalizedLink} red to={link}>
                {linkText || t('findOutMore')}
              </Button>
            ) : (
              <Button
                red
                onClick={() => {
                  let scrolled = false

                  if (scroll) {
                    scrolled = scrollToSection(scroll)
                  } else if (pathname.match(/\/.+\/.+/g)) {
                    scrolled = scrollToNextSibling(lg ? '#browser' : '#form')
                  } else {
                    scrolled = scrollToNextSibling(
                      lg ? '#region-map' : '#header'
                    )
                  }

                  if (!scrolled) {
                    scrollToNextSibling('#header')
                  }
                }}
              >
                {linkText || t('findOutMore')}
              </Button>
            )}
          </>
        )}
      </HeaderContent>
    </HeaderSlideWrapper>
  )
}

export default Slide
