const { default: emitGTMEvent } = require('utils/emitGTMEvent')
const { wrapPageElement, wrapRootElement } = require('./gatsby-shared')

exports.wrapPageElement = wrapPageElement
exports.wrapRootElement = wrapRootElement

exports.onRouteUpdate = ({ location }) => {
  emitGTMEvent({ event: 'page_view', url: location.href })
}

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.search.includes('page')) {
    return false
  }
  const currentPosition = getSavedScrollPosition(location)

  window.scrollTo(...(currentPosition || [0, 0]))

  return true
}
