const React = require('react')
const { ThemeProvider } = require('styled-components')
const { Provider: GraphQlProvider } = require('urql')
const { client } = require('crm')

const { default: theme } = require('styles/theme')
const { default: GlobalStyles } = require('styles/global')

const { NavigationProvider } = require('contexts/NavigationContext')
const { FavouritesProvider } = require('contexts/FavouritesContext')
const { BreakpointsProvider } = require('contexts/BreakpointsContext')
const { ModalProvider } = require('contexts/ModalContext')
const { CookiesProvider } = require('contexts/CookiesContext')

require('swiper/swiper.min.css')
require('react-medium-image-zoom/dist/styles.css')

exports.wrapRootElement = ({ element }) => (
  <GraphQlProvider value={client}>
    <ThemeProvider theme={theme}>
      <BreakpointsProvider>
        <NavigationProvider>
          <FavouritesProvider>
            <CookiesProvider>
              <ModalProvider>{element}</ModalProvider>
            </CookiesProvider>
          </FavouritesProvider>
        </NavigationProvider>
      </BreakpointsProvider>
    </ThemeProvider>
  </GraphQlProvider>
)

exports.wrapPageElement = ({ element }) => {
  return (
    <>
      <GlobalStyles />
      {element}
    </>
  )
}
