import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  PropsWithChildren,
} from 'react'
import emitGTMEvent from 'utils/emitGTMEvent'

import NavigationContext from './NavigationContext'

type ModalState = {
  title: string
  visible: boolean
  formFields: {
    city: boolean | 'Warszawa' | 'Kraków'
    district: boolean
    rooms: number[] | boolean
  }
}

type ModalFormContext = {
  modalState: ModalState
  showModal: () => void
  closeModal: () => void
  updateState: (state: ModalState) => void
}

const DEFAULT_STATE: ModalState = {
  title: 'Zapytaj o inwestycje',
  visible: false,
  formFields: {
    city: false,
    district: false,
    rooms: false,
  },
}

const ModalContext = createContext<ModalFormContext>({
  modalState: DEFAULT_STATE,
  showModal: () => null,
  closeModal: () => null,
  updateState: () => null,
})

export const ModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [modalState, setModalState] = useState<ModalState>(DEFAULT_STATE)
  const { flat } = useContext(NavigationContext)

  const showModal = () => {
    setModalState({
      ...modalState,
      visible: true,
    })
    emitGTMEvent(
      {
        event: 'form_visible',
        form_id: 'header-form',
      },
      flat || undefined
    )
  }

  const closeModal = () => {
    setModalState({ ...modalState, visible: false })
  }

  useEffect(() => {
    if (modalState.visible) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
  }, [modalState.visible])

  const updateState = (newState: ModalState) => {
    setModalState({ ...modalState, ...newState })
  }

  return (
    <ModalContext.Provider
      value={{
        modalState,
        showModal,
        closeModal,
        updateState,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContext
