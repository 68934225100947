import { css, createGlobalStyle } from 'styled-components'

import Normalize from 'styles/normalize'

import { HeaderContent } from 'components/layout/Headers/HomeHeader/Slide'

const Global = css`
  * {
    box-sizing: border-box;
    outline-color: ${({ theme }) => theme.colors.yellow};
  }

  html {
    font-size: ${({ theme }) => theme.fonts.size};
    /* opacity: 0;
    &.wf-active {
      opacity: 1;
    } 
    */

    &.wf-active {
      ${HeaderContent} {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  /* body {
    font-family: Lexend, --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  } */

  button {
    outline: none !important;
  }

  #nprogress .bar {
    height: 3px !important;
    .peg {
      box-shadow: none !important;
    }
  }

  .swiper-wrapper {
    .swiper-slide-duplicate {
      &.swiper-slide-active,
      &.swiper-slide-next,
      &.swiper-slide-previous {
        img[data-main-image] {
          opacity: 1 !important;
        }
      }
    }
  }

  a {
    text-decoration: none;
  }
`

const GlobalStyles = createGlobalStyle`
    ${Normalize}
    ${Global} 
`

export default GlobalStyles
