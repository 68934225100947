import React, {
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react'

import type { Flat, Unit } from 'types/shared/flat'
import { dataLayerFavourites } from 'utils/dataLayer'

type FavouritesContextProps = {
  favourites: (Flat | Unit)[]
  wishlistLimitExceeded: boolean
  checkIsFavourite: (flat: Flat | Unit) => boolean
  toggleFavourite: (flat: Flat | Unit) => void
}

export const WISHLIST_LIMIT = 10
const STORAGE_ITEM_NAME = `favourites-victoriadom`

const FavouritesContext = createContext<FavouritesContextProps>({
  favourites: [],
  wishlistLimitExceeded: false,
  checkIsFavourite: () => false,
  toggleFavourite: () => null,
})

export const FavouritesProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [favourites, setFavourites] = useState<(Flat | Unit)[]>([])

  const wishlistLimitExceeded = favourites.length >= WISHLIST_LIMIT

  const updateFavourites = (newFavs: (Flat | Unit)[]) => {
    localStorage.setItem(STORAGE_ITEM_NAME, JSON.stringify(newFavs))
    setFavourites(newFavs)
  }

  const checkIsFavourite = (flat: Flat | Unit) => {
    if (favourites.length !== 0) {
      return favourites.some(({ id }) => flat.id === id)
    }
    return false
  }

  const removeFavourite = (flat: Flat | Unit) => {
    updateFavourites(favourites.filter(({ id }) => flat.id !== id))
  }

  const addFavorite = (flat: Flat | Unit) => {
    updateFavourites([...favourites, flat])
  }

  const toggleFavourite = (flat: Flat | Unit) => {
    if (checkIsFavourite(flat)) {
      removeFavourite(flat)
    } else if (favourites.length < WISHLIST_LIMIT) {
      dataLayerFavourites(flat)
      addFavorite(flat)
    }
  }

  useEffect(() => {
    const storageFavs = localStorage.getItem(STORAGE_ITEM_NAME)
    const savedFavs: (Flat | Unit)[] = storageFavs
      ? JSON.parse(storageFavs)
      : []

    setFavourites(savedFavs)
  }, [])

  return (
    <FavouritesContext.Provider
      value={{
        favourites,
        wishlistLimitExceeded,
        checkIsFavourite,
        toggleFavourite,
      }}
    >
      {children}
    </FavouritesContext.Provider>
  )
}

export default FavouritesContext
