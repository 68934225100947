import { useMemo } from 'react'
import { useQuery } from 'urql'

import parseFlats from 'utils/parseFlats'

import { buildFlatsQuery, FlatsQueryFilters } from 'crm/queries/flats'

import type { FlatCRM } from 'types/shared/flat'

type Props = {
  filters?: Partial<FlatsQueryFilters>
}

const DEV_MODE = process.env.GATSBY_SHOW_ALL_CRM_FLATS === 'true'

const useFlats = ({ filters }: Props) => {
  const [{ fetching, error, data }] = useQuery<{ flats: FlatCRM[] }>({
    query: buildFlatsQuery({ ...filters }),
    variables: { dev: DEV_MODE, ...filters },
  })
  const flats = useMemo(() => parseFlats(data?.flats ?? []), [data])
  return { loading: fetching, error, flats }
}

export default useFlats
