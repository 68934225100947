/* eslint-disable no-underscore-dangle */
import {
  getInvestmentStage,
  InvestmentID,
  getInvestmentFamilyName,
} from './getInvestmentData'
import { slugify } from './slugify'

import type { Flat, FlatCRM, Unit, UnitCRM } from 'types/shared/flat'

const getStatus = (visible: boolean | null): Flat['status'] => {
  if (visible) return 'available'

  return 'sold'
}

const round = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100

const getType = (type: 0 | 1): Flat['type'] => {
  return type === 0 ? 'unit' : 'flat'
}

const getFlatAsset = (
  folder: 'PNG' | 'PDF',
  ext: 'pdf' | 'png',
  flat: FlatCRM
) => {
  const investmentSlug = slugify(String(flat.investment))
  const buildingSlug = slugify(String(flat.building)).toUpperCase()

  return `${process.env.WORDPRESS_URL}/flats/${investmentSlug}/${folder}/${investmentSlug}-${buildingSlug}-${flat.apartmentNumber}.${ext}`
}

const getUnitAsset = (
  folder: 'PNG' | 'PDF',
  ext: 'pdf' | 'png',
  unit: UnitCRM
) => {
  const investmentSlug = slugify(String(unit.investment))
  const buildingSlug = slugify(String(unit.building)).toUpperCase()

  return `${process.env.WORDPRESS_URL}/units/${investmentSlug}/${folder}/${investmentSlug}-${buildingSlug}-${unit.apartmentNumber}.${ext}`
}

export const parseUnit = (unit: UnitCRM): Unit => ({
  id: String(unit.productInternalNumber),
  type: `unit`,
  unitType: unit.businessUnitType,
  number: unit.apartmentNumber,
  price: Math.ceil(
    Number(unit.totalValueGrossWithDiscount || unit.totalValueGross)
  ),
  floor: Number(unit.floor),
  rooms: Number(unit.roomNumber),
  area: round(unit.apartmentArea),
  additionalArea: round(unit.terraceLikesSumArea),
  gardenArea: round(unit.gardenArea || 0),
  balconyArea: round(unit.balconySumArea || 0),
  status: getStatus(unit.visibleOnWebsite),

  isPriceVisible: unit.priceVisibleOnWebsite,

  image: getUnitAsset('PNG', 'png', unit),
  pdf: getUnitAsset('PDF', 'pdf', unit),

  stage: getInvestmentStage(unit.investment || ''),
  investmentID: String(unit.investmentInternalNumber) as InvestmentID,
  investment: String(unit.investment),
  familyName: getInvestmentFamilyName(unit.investmentFamilyNumber) ?? '',
  building: String(unit.building),

  link: `/lokale-uslugowe/${
    unit.businessUnitType === 'rent' ? 'na-wynajem' : 'na-sprzedaz'
  }/${slugify(`${unit.investment}`)}/${slugify(`${unit.apartmentNumber}`)}`,
  city: String(unit.city),
  district: String(unit.district),
})

export const parseFlat = (flat: FlatCRM): Flat => ({
  id: String(flat.productInternalNumber),
  type: getType(flat.productType),
  number: String(flat.apartmentNumber),
  price: Math.ceil(Number(flat.totalValueGross || 0)),
  floor: flat.floor,
  rooms: flat.roomNumber,
  area: round(Number(flat.apartmentArea)),
  additionalArea: round(
    Number(flat.terraceLikesSumArea) + Number(flat.gardenArea) || 0
  ),
  gardenArea: round(Number(flat.gardenArea) || 0),
  balconyArea: round(Number(flat.balconySumArea) || 0),
  status: getStatus(flat.visibleOnWebsite),

  image: getFlatAsset('PNG', 'png', flat),
  image3D: flat._3dPlan,
  image360: flat._360Projection,
  tour3D: flat._3dWalk,
  pdf: getFlatAsset('PDF', 'pdf', flat),

  promoPrice: flat.totalValueGrossWithDiscount
    ? Math.ceil(Number(flat.totalValueGrossWithDiscount))
    : undefined,
  isPromotion: Boolean(flat.specialOffer),
  isPriceVisible: flat.priceVisibleOnWebsite,
  discount: Boolean(flat.discount),
  savings: flat.totalValueGrossDiscountDifference
    ? Math.ceil(Number(flat.totalValueGrossDiscountDifference))
    : undefined,
  pricePerMeter: flat.totalValueGrossPerMeter
    ? Math.ceil(Number(flat.totalValueGrossPerMeter))
    : undefined,
  priceLowestInLast30Days: flat.totalValueGrossLowestInLast30Days
    ? Math.ceil(Number(flat.totalValueGrossLowestInLast30Days))
    : undefined,

  familyID: String(flat.investmentFamilyNumber || ''),
  familyName: getInvestmentFamilyName(flat.investmentFamilyNumber) || '',
  investmentID: String(flat.investmentInternalNumber) as InvestmentID,
  // TODO: Remove parsing for Olchowy Park 1
  investment:
    flat.investment === 'Olchowy Park'
      ? 'Olchowy Park 1'
      : String(flat.investment),
  stage: getInvestmentStage(flat.investment || ''),
  building: String(flat.building),
  city: String(flat.city),
  // TODO: Remove parsing for Olchowy Park 1
  district: String(flat.district),

  link: `/${slugify(`${flat.city}`)}/${slugify(`${flat.investment}`)}/${slugify(
    `${flat.apartmentNumber}`
  )}`,
  isMezzanine: Boolean(flat.mezzanine),
  isDuplexApartment: Boolean(flat.duplexApartment),

  unitType: undefined,
})

export const parseFlats = (flats: FlatCRM[] | null): Flat[] => {
  if (!flats) return []

  return flats.map(parseFlat)
}

export const parseUnits = (units: UnitCRM[] | null): Unit[] => {
  if (!units) return []

  return units.map(parseUnit)
}

export default parseFlats
