/* eslint-disable import/prefer-default-export */
import { slugify } from './slugify'

export const getAltInvestmentName = (investment: string, building?: string) => {
  if (building === 'Miasteczko Nova Sfera') {
    return 'Miasteczko Nova Sfera 1'
  }
  if (
    building === 'Miasteczko Nova Sfera 2' &&
    investment === 'Miasteczko Nova Sfera'
  ) {
    return 'Miasteczko Nova Sfera 2'
  }
  if (building === 'Viva Piast 2' && investment === 'Viva Piast') {
    return 'Viva Piast 2'
  }
  if (building === 'Olchowy Park 1' && investment === 'Olchowy Park') {
    return 'Olchowy Park 1'
  }
  if (building === 'Osiedle Krzewna 3' && investment === 'Osiedle Krzewna') {
    return 'Osiedle Krzewna 3'
  }
  return investment
}

export const getInvestmentID = (invName: string) => {
  switch (slugify(invName)) {
    case 'miasteczko-nova-ochota-3':
      return '55'
    case 'miasteczko-nova-ochota-4':
      return '57'
    case 'miasteczko-nova-ochota-5':
      return '60'
    case 'miasteczko-nova-ochota-6':
      return '65'
    case 'miasteczko-nova-ochota-7':
    case 'miasteczko-nova-ochota':
      return '77'
    case 'viva-piast':
    case 'viva-piast-1':
      return '58'
    case 'viva-piast-2':
      return '75'
    case 'viva-piast-3':
      return '85'
    case 'viva-piast-4':
      return '104'
    case 'miasteczko-nova-ochota-8':
      return '67'
    case 'miasteczko-nova-ochota-9':
      return '69'
    case 'miasteczko-nova-ochota-10':
      return '74'
    case 'miasteczko-nova-ochota-11':
      return '84'
    case 'miasteczko-nova-sfera':
    case 'miasteczko-nova-sfera-1':
      return '70'
    case 'miasteczko-nova-sfera-2':
      return '76'
    case 'miasteczko-nova-sfera-3':
      return '99'
    case 'moja-ochota-2':
      return '62'
    case 'mloda-wawa':
      return '66'
    case 'osiedle-classic-xiv':
    case 'osiedle-classic':
      return '61'
    case 'osiedle-classic-xii':
      return '56'
    case 'osiedle-krzewna':
    case 'osiedle-krzewna-1':
      return '64'
    case 'osiedle-krzewna-2':
      return '72'
    case 'osiedle-krzewna-3':
      return '86'
    case 'przystanek-tarchomin-3':
      return '71'
    case 'przystanek-tarchomin-5':
      return '87'
    case 'przystanek-tarchomin-4':
    case 'przystanek-tarchomin':
      return '78'
    case 'ursus-factory-6':
    case 'ursus-factory':
      return '63'
    case 'ursus-factory-7':
      return '68'
    case 'olchowy-park-4':
    case 'olchowy-park':
      return '73'
    case 'olchowy-park-1':
      return '79'
    case 'olchowy-park-2-3':
      return '97'
    case 'metro-art':
      return '80'
    case 'metro-art-2':
      return '93'
    case 'metro-art-4':
      return '83'
    case 'metro-art-5':
      return '94'
    case 'metro-art-3-11':
      return '90'
    case 'metro-polczynska-3':
      return '59'
    case 'nova-viva-garden':
      return '81'
    case 'nova-viva-garden-2':
      return '101'
    case 'nova-viva-garden-3':
      return '103'
    case 'nova-viva-garden-4':
      return '102'
    default:
      return null
  }
}

export const getInvestmentStage = (invName: string) => {
  const getCustomInvestmentStage = () => {
    switch (slugify(invName)) {
      case 'osiedle-classic-xiv':
        return 14
      case 'osiedle-classic-xii':
        return 12
      default:
        return null
    }
  }

  const lastPart = invName.split(' ')[invName.split(' ').length - 1]
  const stage = typeof Number(lastPart) === 'number' ? Number(lastPart) : null

  return getCustomInvestmentStage() || stage || 1
}

export const isInvestmentFamily = (invName: string) => {
  const slug = slugify(invName)

  return (
    slug === 'viva-piast' ||
    slug === 'ursus-factory' ||
    slug === 'metro-art' ||
    slug === 'olchowy-park' ||
    slug === 'nova-viva-garden' ||
    slug === 'metro-polczynska' ||
    slug === 'miasteczko-nova-ochota' ||
    slug === 'miasteczko-nova-sfera' ||
    slug === 'osiedle-classic' ||
    slug === 'osiedle-krzewna' ||
    slug === 'moja-ochota' ||
    slug === 'przystanek-tarchomin'
  )
}

export const getInvestmentGroup = (invName: string) => {
  switch (slugify(invName)) {
    case 'miasteczko-nova-ochota-3':
    case 'miasteczko-nova-ochota-4':
    case 'miasteczko-nova-ochota-5':
    case 'miasteczko-nova-ochota-6':
    case 'miasteczko-nova-ochota-7':
    case 'miasteczko-nova-ochota-8':
    case 'miasteczko-nova-ochota-9':
    case 'miasteczko-nova-ochota-10':
    case 'miasteczko-nova-ochota-11':
      return 'Miasteczko Nova Ochota'
    case 'viva-piast':
    case 'viva-piast-1':
    case 'viva-piast-2':
    case 'viva-piast-3':
    case 'viva-piast-4':
      return 'Viva Piast'
    case 'miasteczko-nova-sfera':
    case 'miasteczko-nova-sfera-1':
    case 'miasteczko-nova-sfera-2':
    case 'miasteczko-nova-sfera-3':
      return 'Miasteczko Nova Sfera'
    case 'moja-ochota-2':
      return 'Moja Ochota'
    case 'mloda-wawa':
      return 'Młoda Wawa'
    case 'osiedle-classic-xiv':
    case 'osiedle-classic-xii':
      return 'Osiedle Classic'
    case 'osiedle-krzewna':
    case 'osiedle-krzewna-1':
    case 'osiedle-krzewna-2':
    case 'osiedle-krzewna-3':
      return 'Osiedle Krzewna'
    case 'przystanek-tarchomin-3':
    case 'przystanek-tarchomin-4':
    case 'przystanek-tarchomin-5':
      return 'Przystanek Tarchomin'
    case 'ursus-factory-6':
    case 'ursus-factory-7':
      return 'Ursus Factory'
    case 'olchowy-park':
    case 'olchowy-park-4':
    case 'olchowy-park-1':
    case 'olchowy-park-2-3':
      return 'Olchowy Park'
    case 'metro-art':
    case 'metro-art-2':
    case 'metro-art-4':
    case 'metro-art-5':
    case 'metro-art-3-11':
      return 'Metro Art'
    case 'nova-viva-garden':
    case 'nova-viva-garden-2':
    case 'nova-viva-garden-3':
    case 'nova-viva-garden-4':
      return 'Nova Viva Garden'
    default:
      return null
  }
}

export const getInvestmentFamilyID = (invName: string) => {
  switch (slugify(invName)) {
    case 'miasteczko-nova-ochota':
    case 'miasteczko-nova-ochota-3':
    case 'miasteczko-nova-ochota-4':
    case 'miasteczko-nova-ochota-5':
    case 'miasteczko-nova-ochota-6':
    case 'miasteczko-nova-ochota-7':
    case 'miasteczko-nova-ochota-8':
    case 'miasteczko-nova-ochota-9':
    case 'miasteczko-nova-ochota-10':
    case 'miasteczko-nova-ochota-11':
      return '2'
    case 'viva-piast':
    case 'viva-piast-1':
    case 'viva-piast-2':
    case 'viva-piast-3':
    case 'viva-piast-4':
      return '10'
    case 'miasteczko-nova-sfera':
    case 'miasteczko-nova-sfera-1':
    case 'miasteczko-nova-sfera-2':
    case 'miasteczko-nova-sfera-3':
      return '11'
    case 'moja-ochota':
    case 'moja-ochota-2':
      return '3'
    case 'mloda-wawa':
      return ''
    case 'osiedle-classic':
    case 'osiedle-classic-xiv':
    case 'osiedle-classic-xii':
      return '4'
    case 'osiedle-krzewna':
    case 'osiedle-krzewna-1':
    case 'osiedle-krzewna-2':
    case 'osiedle-krzewna-3':
      return '5'
    case 'przystanek-tarchomin':
    case 'przystanek-tarchomin-3':
    case 'przystanek-tarchomin-4':
    case 'przystanek-tarchomin-5':
      return '6'
    case 'ursus-factory':
    case 'ursus-factory-6':
    case 'ursus-factory-7':
      return '7'
    case 'olchowy-park':
    case 'olchowy-park-4':
    case 'olchowy-park-1':
    case 'olchowy-park-2-3':
      return '9'
    case 'metro-art':
    case 'metro-art-1':
    case 'metro-art-2':
    case 'metro-art-4':
    case 'metro-art-5':
    case 'metro-art-3-11':
      return '12'
    case 'nova-viva-garden':
    case 'nova-viva-garden-1':
    case 'nova-viva-garden-2':
    case 'nova-viva-garden-3':
    case 'nova-viva-garden-4':
      return '13'
    default:
      return null
  }
}

export const getInvestmentFamilyName = (familyID: number | null) => {
  switch (familyID) {
    case 1:
      return 'Metro Połczyńska'
    case 2:
      return 'Miasteczko Nova Ochota'
    case 10:
      return 'Viva Piast'
    case 11:
      return 'Miasteczko Nova Sfera'
    case 3:
      return 'Moja Ochota'
    case null:
      return 'Młoda Wawa'
    case 4:
      return 'Osiedle Classic'
    case 5:
      return 'Osiedle Krzewna'
    case 6:
      return 'Przystanek Tarchomin'
    case 7:
      return 'Ursus Factory'
    case 9:
      return 'Olchowy Park'
    case 12:
      return 'Metro Art'
    case 13:
      return 'Nova Viva Garden'
    default:
      return null
  }
}

export type InvestmentID = ReturnType<typeof getInvestmentID>

export const getInvestmentSelected = (investmentID: InvestmentID) => {
  switch (investmentID) {
    case '56':
    case '61':
      return 'osiedleclassic'
    case '58':
      return 'vivapiast'
    case '59':
      return 'metropolczynska'
    case '62':
      return 'mojaochota2'
    case '63':
      return 'ursusfactory'
    case '64':
    case '86':
    case '72':
      return 'osiedlekrzewna'
    case '66':
      return 'mlodawawa'
    case '68':
      return 'ursusfactory'
    case '70':
    case '76':
    case '99':
      return 'novasfera'
    case '71':
      return 'przystanektarchomin'
    case '73':
    case '97':
    case '79':
      return 'olchowypark'
    case '75':
    case '85':
    case '104':
      return 'vivapiast'
    case '74':
    case '84':
    case '77':
    case '55':
    case '60':
    case '65':
    case '67':
    case '69':
      return 'miasteczkonovaochota'
    case '78':
      return 'przystanektarchomin'
    case '87':
      return 'przystanektarchomin'
    case '80':
    case '93':
    case '83':
    case '90':
    case '94':
      return 'metroart'
    case '81':
    case '101':
    case '103':
    case '102':
      return 'novavivagarden'
    default:
      return null
  }
}

export const getInvestmentName = (investmentID: InvestmentID) => {
  switch (investmentID) {
    case '55':
      return 'Miasteczko Nova Ochota'
    case '56':
      return 'Miasteczko Classic'
    case '58':
      return 'Viva Piast'
    case '59':
      return 'Metro Połczyńska'
    case '60':
      return 'Miasteczko Nova Ochota'
    case '61':
      return 'Miasteczko Classic'
    case '62':
      return 'Moja Ochota 2'
    case '63':
      return 'Ursus Factory'
    case '64':
      return 'Osiedle Krzewna'
    case '65':
      return 'Miasteczko Nova Ochota'
    case '66':
      return 'Młoda Wawa'
    case '67':
      return 'Miasteczko Nova Ochota'
    case '68':
      return 'Ursus Factory'
    case '69':
      return 'Miasteczko Nova Ochota'
    case '70':
    case '76':
    case '99':
      return 'Nova Sfera'
    case '71':
      return 'Przystanek Tarchomin'
    case '72':
      return 'Osiedle Krzewna'
    case '86':
      return 'Osiedle Krzewna'
    case '73':
      return 'Olchowy Park'
    case '97':
      return 'Olchowy Park'
    case '74':
      return 'Miasteczko Nova Ochota'
    case '75':
    case '85':
    case '104':
      return 'Viva Piast'
    case '77':
      return 'Miasteczko Nova Ochota'
    case '78':
      return 'Przystanek Tarchomin'
    case '87':
      return 'Przystanek Tarchomin'
    case '79':
      return 'Olchowy Park 1'
    case '80':
      return 'Metro Art'
    case '93':
      return 'Metro Art'
    case '83':
      return 'Metro Art'
    case '90':
      return 'Metro Art'
    case '81':
      return 'Nova Viva Garden'
    case '101':
      return 'Nova Viva Garden'
    case '103':
      return 'Nova Viva Garden'
    case '102':
      return 'Nova Viva Garden'
    case '84':
      return 'Miasteczko Nova Ochota'
    default:
      return null
  }
}

export const getInvestmentCity = (investmentID: InvestmentID) => {
  switch (investmentID) {
    case '77':
      return 'Kraków'

    default:
      return 'Warszawa'
  }
}

export const parseInvestmentFromUrl = (investment: string) =>
  investment
    .split('-')
    .map((word) =>
      word
        .toUpperCase()
        .match(/^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/)
        ? word.toUpperCase()
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(' ')
    .replace('Polczynska', 'Połczyńska')
    .replace('Mloda', 'Młoda')
