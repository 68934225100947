/* eslint-disable import/prefer-default-export */
import WEBSITE_PARAMS from 'constants/params'
import { navigate } from 'gatsby'
import isSSR from './isSSR'

const dummyDOMStringList: DOMStringList = {
  length: 0,
  contains: function (string: string): boolean {
    throw new Error('Function not implemented.' + string)
  },
  item: function (index: number): string | null {
    throw new Error('Function not implemented.' + index)
  },
}

const dummyLocation: Location = {
  ancestorOrigins: dummyDOMStringList,
  hash: '',
  host: '',
  hostname: '',
  href: '',
  origin: '',
  pathname: '',
  port: '',
  protocol: '',
  search: '',
  assign: function (url: string | URL): void {
    throw new Error('Function not implemented.' + url)
  },
  reload: function (): void {
    throw new Error('Function not implemented.')
  },
  replace: function (url: string | URL): void {
    throw new Error('Function not implemented.' + url)
  },
}

export const getCurrentLocation = () =>
  !isSSR
    ? window?.location ?? document?.location ?? dummyLocation
    : dummyLocation

export const getUrl = (location: Location = getCurrentLocation()) =>
  location.href

export const getPathname = (location: Location = getCurrentLocation()) =>
  location.pathname

export const getParams = (location: Location = getCurrentLocation()) =>
  new URLSearchParams(location.search)

export const getParam = (
  param: string,
  location: Location = getCurrentLocation()
) => getParams(location).get(param)

export const URLSearchParamsToObject = (params: URLSearchParams) => {
  const result: Record<string, string> = {}
  params.forEach((value, key) => {
    result[key] = value
  })
  return result
}

export const saveUTMParams = (params: URLSearchParams = getParams()) => {
  const UTMParams: Record<string, string> = {}

  params.forEach((value, key) => {
    if (!WEBSITE_PARAMS.some((el) => key === el)) {
      UTMParams[key] = value
    }
  })
  sessionStorage.setItem('UTM_PARAMS', JSON.stringify(UTMParams))
}

export const updateURLParams = (
  params: Record<string, string>,
  overrideProps: boolean,
  replaceHistory: boolean,
  location: Location = getCurrentLocation()
) => {
  const urlParams = overrideProps ? new URLSearchParams() : getParams(location)
  Object.entries(params).forEach(([key, value]) => {
    urlParams.set(key, value)
  })
  const queryString = urlParams.toString() ? `?${urlParams.toString()}` : ''
  const newURL = `${getPathname(location)}${queryString}`

  if (replaceHistory && !isSSR) {
    window.history.replaceState({}, '', newURL)
  } else {
    navigate(newURL, { replace: replaceHistory })
  }
}
