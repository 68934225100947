/* eslint-disable import/prefer-default-export */
import React, { PropsWithChildren } from 'react'
import styled, { css, CSSProperties } from 'styled-components'

import omitProps from 'utils/omitProps'

import { pxToRem } from 'styles/mixins'
import media from 'styles/media'

import arrowRight from 'assets/icons/arrow-right-red.svg'

import type {
  TextProps,
  TextColor,
  WithLineProps,
} from 'types/shared/typography'

const TextCustomProps = [
  'themecolor',
  'size',
  'sizeDiff',
  'align',
  'weight',
  'align',
  'margin',
  'transform',
  'decoration',
]

export const Heading = styled.h1.withConfig<TextProps>(
  omitProps(TextCustomProps)
)`
  display: block;

  font-weight: ${({ weight }) => weight ?? '700'};
  line-height: ${({ line }) => line ?? 'initial'};
  font-size: ${({ size }) =>
    size ? `${pxToRem(size)}rem` : `${pxToRem(90)}rem`};
  text-align: ${({ align }) => align ?? 'left'};
  text-transform: ${({ transform }) => transform ?? 'initial'};
  text-decoration: ${({ decoration }) => decoration ?? 'none'};
  color: ${({ theme, themecolor }) =>
    themecolor ? theme.colors[themecolor] : theme.colors.dark};

  margin-bottom: ${({ margin }) => margin ?? '1.5rem'};

  ${({ size, sizeDiff = 0.5 }) => css`
    ${media.xxxl.max} {
      font-size: ${size
        ? `${pxToRem(size * (1 - sizeDiff / 10))}rem`
        : `${pxToRem(82)}rem`};
    }

    ${media.xxl.max} {
      font-size: ${size
        ? `${pxToRem(size * (1 - (sizeDiff / 10) * 2))}rem`
        : `${pxToRem(74)}rem`};
    }

    ${media.xl.max} {
      font-size: ${size
        ? `${pxToRem(size * (1 - (sizeDiff / 10) * 3))}rem`
        : `${pxToRem(62)}rem`};
    }

    ${media.lg.max} {
      font-size: ${size
        ? `${pxToRem(size * (1 - (sizeDiff / 10) * 4))}rem`
        : `${pxToRem(54)}rem`};
    }

    ${media.md.max} {
      font-size: ${size
        ? `${pxToRem(size * (1 - (sizeDiff / 10) * 5))}rem`
        : `${pxToRem(48)}rem`};
    }

    ${media.sm.max} {
      font-size: ${size
        ? `${pxToRem(size * (1 - (sizeDiff / 10) * 6))}rem`
        : `${pxToRem(42)}rem`};
    }
    @media (max-width: 360px) {
      font-size: ${size
        ? `${pxToRem(size * (1 - (sizeDiff / 10) * 7))}rem`
        : `${pxToRem(36)}rem`};
    }
  `}
`

export const Highlight = styled(Heading).attrs({
  as: 'h3',
  size: 140,
  themecolor: 'yellow',
})`
  font-family: TheSecret, sans-serif;
  font-weight: 400;
  margin-top: -25px;
`

Highlight.defaultProps = {
  sizeDiff: 0.75,
}

export const Text = styled.p.withConfig<Omit<TextProps, 'sizeDiff'>>(
  omitProps(TextCustomProps)
)`
  display: block;
  line-height: ${({ line }) => line ?? '1.4'};
  font-weight: ${({ weight }) => weight ?? '400'};
  text-transform: ${({ transform }) => transform ?? 'initial'};
  font-size: ${({ size }) => (size ? `${pxToRem(size)}rem` : '1rem')};
  text-align: ${({ align }) => align ?? 'left'};
  text-decoration: ${({ decoration }) => decoration ?? 'none'};
  color: ${({ theme, themecolor }) =>
    themecolor ? theme.colors[themecolor] : theme.colors.text};

  margin-bottom: ${({ margin }) => margin ?? '1rem'};

  transition: color 150ms ease;

  a {
    color: inherit;
  }
`

export const Decorative = styled(Text)`
  font-family: TheSecret, sans-serif;
`

export const Light = styled.span`
  font-weight: 300;
`

export const Medium = styled.span`
  font-weight: 500;
`

export const SemiBold = styled.span`
  font-weight: 600;
`

export const Bold = styled.span`
  font-weight: 700;
`

export const Color = styled.span<TextColor>`
  color: ${({ theme, themecolor }) =>
    themecolor ? theme.colors[themecolor] : theme.colors.text} !important;
`

export const Content = styled.div<{ line?: CSSProperties['lineHeight'] }>`
  b {
    font-weight: 500;
    font-size: 1.1em;
    color: ${({ theme }) => theme.colors.black};
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0.5rem 0 1.5rem;

    li {
      padding-left: 1.4em;
      text-indent: -1.2em;
      margin: 0 0 0.25rem;

      &::before {
        content: '';

        display: inline-block;
        width: 7px;
        height: 7px;

        margin-right: 12px;

        border-radius: 50%;
        background: ${({ theme }) => theme.colors.red};
      }
    }
  }

  p,
  li {
    line-height: ${({ line }) => line || '1.9'};
    font-weight: 400;

    font-size: 1rem;
    text-align: left;

    color: ${({ theme }) => theme.colors.text};
    ${media.md.max} {
      line-height: ${({ line }) => line || '1.55'};
    }
  }

  p {
    display: block;
    margin-bottom: 1.5rem;
  }

  a {
    color: ${({ theme }) => theme.colors.red};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;

    font-weight: 600;
    text-align: left;
    color: ${({ theme }) => theme.colors.dark};

    margin: 1rem 0 1.5rem;
    ${media.md.max} {
      margin: 1rem 0;
    }
  }
  h5,
  h6 {
    margin: 0.5rem 0;
  }

  h1 {
    font-size: ${pxToRem(30)}rem;
    ${media.md.max} {
      font-size: ${pxToRem(26)}rem;
    }
  }
  h2 {
    font-size: ${pxToRem(26)}rem;
    ${media.md.max} {
      font-size: ${pxToRem(22)}rem;
    }
  }
  h3 {
    font-size: ${pxToRem(24)}rem;
    ${media.md.max} {
      font-size: ${pxToRem(21)}rem;
    }
  }
  h4 {
    font-size: ${pxToRem(22)}rem;
    ${media.md.max} {
      font-size: ${pxToRem(20)}rem;
    }
  }
  h5 {
    font-size: ${pxToRem(20)}rem;
  }
  h6 {
    font-size: ${pxToRem(18)}rem;
  }

  img {
    display: block;
    width: 100%;
    margin: 1rem 0 2rem;
  }
  .small {
    font-size: ${pxToRem(14)}rem;
    line-height: 1.6;
  }
  .tiny {
    font-size: ${pxToRem(12)}rem;
    line-height: 1.5;
  }
  .links {
    a {
      display: flex;
      font-size: ${pxToRem(14)}rem;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 600;
      margin: 3px 0;
      &:hover:before {
        margin-right: 15px;
      }
      &:before {
        content: '';
        display: block;
        width: 16px;
        height: 12px;
        background: url(${arrowRight}) no-repeat center;
        background-size: 100%;
        margin-top: 5px;
        margin-right: 10px;
        transition: 0.2s;
      }
    }
  }
`

const WithLineWrapper = styled.div<WithLineProps>`
  position: relative;
  display: flex;
  align-items: center;

  padding-left: 1.5rem;
  margin: ${({ margin }) => margin || '0'};

  &::before {
    content: '';

    position: absolute;
    top: 50%;
    left: 0;

    transform: translate3d(0, -50%, 0);

    display: block;
    width: 3px;
    height: 90%;

    background: ${({ theme }) => theme.colors.red};
  }
`

export const WithLine: React.FC<PropsWithChildren<WithLineProps>> = ({
  children,
  margin,
}) => (
  <WithLineWrapper margin={margin}>
    <div>{children}</div>
  </WithLineWrapper>
)

export const FooterHeading = styled(Heading).attrs({ as: 'h4' })`
  color: ${({ theme }) => theme.colors.darkyellow};
  font-weight: 500;
  text-transform: uppercase;
`

FooterHeading.defaultProps = {
  size: 13,
  sizeDiff: 0,
  margin: '0.35rem',
}

export const FooterOfficesHeading = styled(Heading).attrs({ as: 'h4' })`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.red};
  font-weight: 600;
`
FooterOfficesHeading.defaultProps = {
  size: 18,
  sizeDiff: 0,
  margin: '1.15rem',
}
