exports.components = {
  "component---src-layouts-bond-tsx": () => import("./../../../src/layouts/Bond.tsx" /* webpackChunkName: "component---src-layouts-bond-tsx" */),
  "component---src-layouts-completed-investment-tsx": () => import("./../../../src/layouts/CompletedInvestment.tsx" /* webpackChunkName: "component---src-layouts-completed-investment-tsx" */),
  "component---src-layouts-district-layout-tsx": () => import("./../../../src/layouts/DistrictLayout.tsx" /* webpackChunkName: "component---src-layouts-district-layout-tsx" */),
  "component---src-layouts-investment-group-tsx": () => import("./../../../src/layouts/InvestmentGroup.tsx" /* webpackChunkName: "component---src-layouts-investment-group-tsx" */),
  "component---src-layouts-investment-tsx": () => import("./../../../src/layouts/Investment.tsx" /* webpackChunkName: "component---src-layouts-investment-tsx" */),
  "component---src-layouts-log-building-tsx": () => import("./../../../src/layouts/LogBuilding.tsx" /* webpackChunkName: "component---src-layouts-log-building-tsx" */),
  "component---src-layouts-message-tsx": () => import("./../../../src/layouts/Message.tsx" /* webpackChunkName: "component---src-layouts-message-tsx" */),
  "component---src-layouts-news-tsx": () => import("./../../../src/layouts/News.tsx" /* webpackChunkName: "component---src-layouts-news-tsx" */),
  "component---src-layouts-report-tsx": () => import("./../../../src/layouts/Report.tsx" /* webpackChunkName: "component---src-layouts-report-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-[city]-[investment]-[flat]-tsx": () => import("./../../../src/pages/[city]/[investment]/[flat].tsx" /* webpackChunkName: "component---src-pages-[city]-[investment]-[flat]-tsx" */),
  "component---src-pages-aktualnosci-tsx": () => import("./../../../src/pages/aktualnosci.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-tsx" */),
  "component---src-pages-dane-kontaktowe-tsx": () => import("./../../../src/pages/dane-kontaktowe.tsx" /* webpackChunkName: "component---src-pages-dane-kontaktowe-tsx" */),
  "component---src-pages-dni-otwarte-tsx": () => import("./../../../src/pages/dni-otwarte.tsx" /* webpackChunkName: "component---src-pages-dni-otwarte-tsx" */),
  "component---src-pages-dobrze-zaplanowane-tsx": () => import("./../../../src/pages/dobrze-zaplanowane.tsx" /* webpackChunkName: "component---src-pages-dobrze-zaplanowane-tsx" */),
  "component---src-pages-dziennik-budowy-tsx": () => import("./../../../src/pages/dziennik-budowy.tsx" /* webpackChunkName: "component---src-pages-dziennik-budowy-tsx" */),
  "component---src-pages-gotowe-do-odbioru-tsx": () => import("./../../../src/pages/gotowe-do-odbioru.tsx" /* webpackChunkName: "component---src-pages-gotowe-do-odbioru-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-informacje-o-spolce-tsx": () => import("./../../../src/pages/informacje-o-spolce.tsx" /* webpackChunkName: "component---src-pages-informacje-o-spolce-tsx" */),
  "component---src-pages-informacje-prasowe-tsx": () => import("./../../../src/pages/informacje-prasowe.tsx" /* webpackChunkName: "component---src-pages-informacje-prasowe-tsx" */),
  "component---src-pages-inwestycje-zrealizowane-tsx": () => import("./../../../src/pages/inwestycje-zrealizowane.tsx" /* webpackChunkName: "component---src-pages-inwestycje-zrealizowane-tsx" */),
  "component---src-pages-jesienna-filozofia-hygge-tsx": () => import("./../../../src/pages/jesienna-filozofia-hygge.tsx" /* webpackChunkName: "component---src-pages-jesienna-filozofia-hygge-tsx" */),
  "component---src-pages-klauzula-informacyjna-tsx": () => import("./../../../src/pages/klauzula-informacyjna.tsx" /* webpackChunkName: "component---src-pages-klauzula-informacyjna-tsx" */),
  "component---src-pages-komunikaty-tsx": () => import("./../../../src/pages/komunikaty.tsx" /* webpackChunkName: "component---src-pages-komunikaty-tsx" */),
  "component---src-pages-kontakt-biura-sprzedazy-tsx": () => import("./../../../src/pages/kontakt/biura-sprzedazy.tsx" /* webpackChunkName: "component---src-pages-kontakt-biura-sprzedazy-tsx" */),
  "component---src-pages-kontakt-czesto-zadawane-pytania-tsx": () => import("./../../../src/pages/kontakt/czesto-zadawane-pytania.tsx" /* webpackChunkName: "component---src-pages-kontakt-czesto-zadawane-pytania-tsx" */),
  "component---src-pages-kontakt-index-tsx": () => import("./../../../src/pages/kontakt/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-index-tsx" */),
  "component---src-pages-kontakt-iod-tsx": () => import("./../../../src/pages/kontakt/iod.tsx" /* webpackChunkName: "component---src-pages-kontakt-iod-tsx" */),
  "component---src-pages-kontakt-obsluga-posprzedazowa-tsx": () => import("./../../../src/pages/kontakt/obsluga-posprzedazowa.tsx" /* webpackChunkName: "component---src-pages-kontakt-obsluga-posprzedazowa-tsx" */),
  "component---src-pages-kontakt-serwis-techniczny-tsx": () => import("./../../../src/pages/kontakt/serwis-techniczny.tsx" /* webpackChunkName: "component---src-pages-kontakt-serwis-techniczny-tsx" */),
  "component---src-pages-kontakt-siedziba-firmy-tsx": () => import("./../../../src/pages/kontakt/siedziba-firmy.tsx" /* webpackChunkName: "component---src-pages-kontakt-siedziba-firmy-tsx" */),
  "component---src-pages-krakow-tsx": () => import("./../../../src/pages/krakow.tsx" /* webpackChunkName: "component---src-pages-krakow-tsx" */),
  "component---src-pages-kredyt-tsx": () => import("./../../../src/pages/kredyt.tsx" /* webpackChunkName: "component---src-pages-kredyt-tsx" */),
  "component---src-pages-lokale-uslugowe-[type]-[investment]-[unit]-tsx": () => import("./../../../src/pages/lokale-uslugowe/[type]/[investment]/[unit].tsx" /* webpackChunkName: "component---src-pages-lokale-uslugowe-[type]-[investment]-[unit]-tsx" */),
  "component---src-pages-lokale-uslugowe-index-tsx": () => import("./../../../src/pages/lokale-uslugowe/index.tsx" /* webpackChunkName: "component---src-pages-lokale-uslugowe-index-tsx" */),
  "component---src-pages-lokale-uslugowe-na-sprzedaz-tsx": () => import("./../../../src/pages/lokale-uslugowe/na-sprzedaz.tsx" /* webpackChunkName: "component---src-pages-lokale-uslugowe-na-sprzedaz-tsx" */),
  "component---src-pages-lokale-uslugowe-na-wynajem-tsx": () => import("./../../../src/pages/lokale-uslugowe/na-wynajem.tsx" /* webpackChunkName: "component---src-pages-lokale-uslugowe-na-wynajem-tsx" */),
  "component---src-pages-mieszkania-z-ogrodkami-tsx": () => import("./../../../src/pages/mieszkania-z-ogrodkami.tsx" /* webpackChunkName: "component---src-pages-mieszkania-z-ogrodkami-tsx" */),
  "component---src-pages-mikolajki-w-victoria-dom-tsx": () => import("./../../../src/pages/mikolajki-w-victoria-dom.tsx" /* webpackChunkName: "component---src-pages-mikolajki-w-victoria-dom-tsx" */),
  "component---src-pages-obligacje-tsx": () => import("./../../../src/pages/obligacje.tsx" /* webpackChunkName: "component---src-pages-obligacje-tsx" */),
  "component---src-pages-oferty-specjalne-tsx": () => import("./../../../src/pages/oferty-specjalne.tsx" /* webpackChunkName: "component---src-pages-oferty-specjalne-tsx" */),
  "component---src-pages-polityka-cookies-tsx": () => import("./../../../src/pages/polityka-cookies.tsx" /* webpackChunkName: "component---src-pages-polityka-cookies-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-poznaj-filozofie-hygge-tsx": () => import("./../../../src/pages/poznaj-filozofie-hygge.tsx" /* webpackChunkName: "component---src-pages-poznaj-filozofie-hygge-tsx" */),
  "component---src-pages-poznaj-nas-tsx": () => import("./../../../src/pages/poznaj-nas.tsx" /* webpackChunkName: "component---src-pages-poznaj-nas-tsx" */),
  "component---src-pages-program-rzadowy-bezpieczny-kredyt-2-tsx": () => import("./../../../src/pages/program-rzadowy-bezpieczny-kredyt-2.tsx" /* webpackChunkName: "component---src-pages-program-rzadowy-bezpieczny-kredyt-2-tsx" */),
  "component---src-pages-projekt-inwestycyjny-tsx": () => import("./../../../src/pages/projekt-inwestycyjny.tsx" /* webpackChunkName: "component---src-pages-projekt-inwestycyjny-tsx" */),
  "component---src-pages-publiczny-program-emisji-obligacji-tsx": () => import("./../../../src/pages/publiczny-program-emisji-obligacji.tsx" /* webpackChunkName: "component---src-pages-publiczny-program-emisji-obligacji-tsx" */),
  "component---src-pages-raporty-tsx": () => import("./../../../src/pages/raporty.tsx" /* webpackChunkName: "component---src-pages-raporty-tsx" */),
  "component---src-pages-ulubione-tsx": () => import("./../../../src/pages/ulubione.tsx" /* webpackChunkName: "component---src-pages-ulubione-tsx" */),
  "component---src-pages-warszawa-tsx": () => import("./../../../src/pages/warszawa.tsx" /* webpackChunkName: "component---src-pages-warszawa-tsx" */),
  "component---src-pages-wszystkie-mieszkania-tsx": () => import("./../../../src/pages/wszystkie-mieszkania.tsx" /* webpackChunkName: "component---src-pages-wszystkie-mieszkania-tsx" */)
}

