/* eslint-disable import/prefer-default-export */
import type Flat from 'types/shared/flat'

export const sortNumbers = (
  a: number | undefined,
  b: number | undefined,
  order: number
) => {
  if (typeof a === 'number' && typeof b === 'number') {
    if (a < b) {
      return -1 * order
    }
    if (a > b) {
      return 1 * order
    }
  }

  return 0
}

export const sortPrices = (
  a: number | undefined,
  b: number | undefined,
  order: number
) => {
  if (typeof a === 'number' && typeof b === 'number') {
    return sortNumbers(a, b, order)
  }

  if (typeof a === 'number') {
    return -1
  }
  if (typeof b === 'number') {
    return 1
  }

  return 0
}

export const sortAsNumbers =
  (order = 1) =>
  (a: number, b: number) =>
    (a - b) * order

export const sortString =
  (order = 1) =>
  (a: string, b: string) =>
    a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: 'base',
    }) * order

export const sortStatus = (
  a: Flat['status'],
  b: Flat['status'],
  order: number
) => {
  if (a < b) {
    return -1 * order
  }
  if (a > b) {
    return 1 * order
  }
  return 0
}
