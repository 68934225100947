import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from 'react'
import Cookie from 'js-cookie'

type CookiesState = {
  areCookiesActive: boolean
}

type CookiesContextType = CookiesState & {
  acceptCookies: () => void
  rejectCookies: () => void
}

const DEFAULT_STATE: CookiesState = {
  areCookiesActive: false,
}

const CookiesContext = createContext<CookiesContextType>({
  ...DEFAULT_STATE,
  acceptCookies: () => null,
  rejectCookies: () => null,
})

export const CookiesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [cookiesState, setCookiesState] = useState<CookiesState>(DEFAULT_STATE)

  const acceptCookies = () => {
    Cookie.set('victoriadom-cookies', 'true')
    localStorage.setItem('victoriadom-cookies', 'true')
    setCookiesState({ ...cookiesState, areCookiesActive: true })
  }
  const rejectCookies = () => {
    Cookie.set('victoriadom-cookies', 'false')
    localStorage.setItem('victoriadom-cookies', 'false')
    setCookiesState({ ...cookiesState, areCookiesActive: false })
  }

  useEffect(() => {
    const value = localStorage.getItem('victoriadom-cookies')
    if (value) {
      Cookie.set('victoriadom-cookies', value)
      setCookiesState({ ...cookiesState, areCookiesActive: value === 'true' })
    }
  }, [])

  return (
    <CookiesContext.Provider
      value={{
        ...cookiesState,
        acceptCookies,
        rejectCookies,
      }}
    >
      {children}
    </CookiesContext.Provider>
  )
}

export default CookiesContext
