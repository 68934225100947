import { gql } from 'urql'
import { query } from 'gql-query-builder'
import { getInvestmentFamilyID, getInvestmentID } from 'utils/getInvestmentData'
import type { Districts } from 'types/layout/flatBrowser'

export type FlatsQueryFilters = {
  apartmentArea?: string
  apartmentNumber?: string
  city?: string
  district?: string
  finished?: boolean
  finishedThisYear?: boolean
  investment?: string
  investmentInternalNumber?: number
  investmentFamily?: string
  investmentFamilyNumber?: number
  productInternalNumber?: number
  specialOffer?: boolean
  visibleOnWebsite?: boolean
}

type GenerateQueryFiltersArguments = {
  investmentID?: ReturnType<typeof getInvestmentID>
  investmentFamilyID?: ReturnType<typeof getInvestmentFamilyID>
  building?: string
  district?: Districts | Districts[]
  onlyAvailable?: boolean
}
export const generateQueryFilters = ({
  investmentID,
  investmentFamilyID,
  building,
  district,
  onlyAvailable = true,
}: GenerateQueryFiltersArguments) => ({
  ...(onlyAvailable ? { visibleOnWebsite: true } : {}),
  ...(district && !Array.isArray(district) ? { district } : {}),
  ...(district
    ? {}
    : building || !investmentFamilyID
    ? investmentID
      ? { investmentInternalNumber: Number(investmentID) }
      : {}
    : { investmentFamilyNumber: Number(investmentFamilyID) }),
})

const DEV_MODE = process.env.GATSBY_SHOW_ALL_CRM_FLATS === 'true'

export const buildFlatsQuery = (filters: FlatsQueryFilters) => {
  const result = query({
    operation: 'flats',
    variables: { dev: DEV_MODE, ...filters },
    fields: [
      '_360Projection',
      '_3dPlan',
      '_3dWalk',
      'apartmentArea',
      'apartmentNumber',
      'balconySumArea',
      'building',
      'city',
      'directionsOfTheWorld',
      'discount',
      'district',
      'duplexApartment',
      'finished',
      'finishedThisYear',
      'floor',
      'gardenArea',
      'investment',
      'investmentFamilyNumber',
      'investmentInternalNumber',
      'loggiaArea',
      'mezzanine',
      'plannedInvestmentCompletionDate',
      'priceVisibleOnWebsite',
      'productInternalNumber',
      'productType',
      'roomNumber',
      'specialOffer',
      'terraceArea',
      'terraceLikesSumArea',
      'totalValueGross',
      'totalValueGrossDiscountDifference',
      'totalValueGrossLowestInLast30Days',
      'totalValueGrossPerMeter',
      'totalValueGrossWithDiscount',
      'visibleOnWebsite',
    ],
  })

  return gql`
    ${result.query}
  `
}

export const similarFlatsQuery = gql`
  query SimilarFlatsQuery($flatID: Int, $quantity: Int) {
    flatsSimilar(output: $quantity, flatId: $flatID) {
      _360Projection
      _3dPlan
      _3dWalk
      apartmentArea
      apartmentNumber
      balconySumArea
      building
      city
      directionsOfTheWorld
      discount
      district
      duplexApartment
      finished
      finishedThisYear
      floor
      gardenArea
      investment
      investmentFamilyNumber
      investmentInternalNumber
      loggiaArea
      mezzanine
      plannedInvestmentCompletionDate
      priceVisibleOnWebsite
      productInternalNumber
      productType
      roomNumber
      specialOffer
      terraceArea
      terraceLikesSumArea
      totalValueGross
      totalValueGrossDiscountDifference
      totalValueGrossLowestInLast30Days
      totalValueGrossPerMeter
      totalValueGrossWithDiscount
      visibleOnWebsite
    }
  }
`
