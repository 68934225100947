import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  PropsWithChildren,
} from 'react'
import { navigate as gatsbyNavigate } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'

import useDefaultFilters from 'hooks/useDefaultFilters'
import { saveUTMParams } from 'utils/URLParams'

import type { Flat, Unit } from 'types/shared/flat'
import type {
  Cities,
  Districts,
  Filters,
  Investments,
} from 'types/layout/flatBrowser'

type State = {
  flat: Flat | Unit | null
  district: Districts | null
  investment: string | null
  investmentGroup: Investments | null
  city: Cities | null
  altLocation: `/${string}` | null
  promotion: boolean
  finished: boolean
}

type NavigationContextValue = State & {
  defaultFilters: Filters
  updateState: (state: State) => void
  navigate: (path: string, locale?: string) => void
}

const DEFAULT_STATE: State = {
  investment: null,
  investmentGroup: null,
  flat: null,
  district: null,
  city: null,
  altLocation: null,
  promotion: false,
  finished: false,
}

const NavigationContext = createContext<NavigationContextValue>({
  ...DEFAULT_STATE,
  defaultFilters: {
    floor: undefined,
    rooms: undefined,
    area: undefined,
    price: undefined,
    stage: undefined,
    investments: undefined,
    locations: undefined,
    status: undefined,
    isMezzanine: false,
    isDuplexApartment: false,
    gardenArea: undefined,
  },
  updateState: () => null,
  navigate: () => null,
})

export const NavigationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { defaultLang } = useLocalization()
  const [state, setState] = useState<State>(DEFAULT_STATE)
  const { filters: defaultFilters } = useDefaultFilters({
    city: state.city ?? undefined,
    district: state.district ?? undefined,
    investmentFamily: state.investmentGroup ?? undefined,
    investment: state.investment ?? undefined,
  })

  const updateState = (newState: State) => {
    setState({ ...state, ...newState })
  }

  const navigate = (path: string, locale?: string) => {
    const prefix = !locale || locale === defaultLang ? `` : `/${locale}`
    gatsbyNavigate(`${prefix + path}`)
  }

  useEffect(() => {
    saveUTMParams()
  }, [])

  const providerValue = useMemo(
    () => ({
      ...state,
      defaultFilters,
      updateState,
      navigate,
    }),
    [state, defaultFilters]
  )

  return (
    <NavigationContext.Provider value={providerValue}>
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationContext
