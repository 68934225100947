import styled, { css, CSSProperties } from 'styled-components'
import { darken, readableColor } from 'polished'

import { Colors } from 'types/theme'
import { colors } from 'styles/theme'
import { pxToRem } from 'styles/mixins'
import media from 'styles/media'

import loadingIcon from 'assets/icons/loading.svg'

const ButtonCustomProps = [
  ...Object.keys(colors),
  'outline',
  'loading',
  'round',
  'roundSize',
  'marginRight',
  'marginLeft',
  'width',
  'fontSize',
  'slim',
  'active',
  'darkenHover',
]

type ButtonProps = {
  [key in keyof Colors]?: boolean
} & {
  outline?: boolean
  loading?: boolean
  round?: boolean
  roundSize?: CSSProperties['width']
  marginRight?: boolean
  marginLeft?: boolean
  slim?: boolean

  width?: CSSProperties['width']
  fontSize?: number
  darkenHover?: boolean
}

const Button = styled.button
  .attrs<{ className: string }>({ className: 'button' })
  .withConfig({
    shouldForwardProp: (prop) => !ButtonCustomProps.includes(prop),
  })<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: relative;

  padding: ${({ round, slim }) =>
    round
      ? `${pxToRem(12)}em`
      : `${pxToRem(slim ? 18 / 1.5 : 18)}em ${pxToRem(36)}em`};

  ${media.sm.max} {
    padding: ${({ round }) =>
      round ? `${pxToRem(12)}em` : `${pxToRem(13)}em ${pxToRem(36)}em`};
  }

  border: 2px solid transparent;
  border-radius: 30px;

  font-size: ${({ fontSize }) =>
    fontSize ? `${pxToRem(fontSize)}rem` : `${pxToRem(14)}rem`};
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
  transition: color 200ms ease, background-color 200ms ease,
    border-color 200ms ease;

  ${({ width }) =>
    width &&
    css`
      width: 100%;
      max-width: ${width};
    `}

  ${({ round, roundSize }) =>
    round &&
    css`
      border-radius: 50%;
      min-width: ${roundSize};
      min-height: ${roundSize};
      max-width: ${roundSize};
      max-height: ${roundSize};
    `}

  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${pxToRem(6)}em;
    `}

  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${pxToRem(6)}em;
    `}

  ${(props) => {
    const keys = Object.keys(props)
    const colorProps = Object.keys(colors)

    for (const key of keys) {
      if (
        colorProps.includes(key) &&
        Boolean(props[key as keyof typeof props])
      ) {
        return css`
          background: ${({ theme }) => theme.colors[key as keyof Colors]};
          border-color: ${({ theme }) => theme.colors[key as keyof Colors]};
          color: ${({ theme }) =>
            readableColor(
              darken(0.02, String(theme.colors[key as keyof Colors])),
              theme.colors.dark,
              theme.colors.white
            )};

          &:hover {
            background: ${({ theme }) =>
              key === 'red' && !props.darkenHover
                ? theme.colors.yellow
                : darken(0.05, String(theme.colors[key as keyof Colors]))};
            border-color: ${({ theme }) =>
              key === 'red' && !props.darkenHover
                ? theme.colors.yellow
                : darken(0.05, String(theme.colors[key as keyof Colors]))};
            color: ${({ theme }) =>
              readableColor(
                darken(0.05, String(theme.colors[key as keyof Colors])),
                theme.colors.dark,
                theme.colors.white
              )};
          }
        `
      }
    }

    return null
  }}

    ${({ outline }) =>
      outline &&
      css`
        background: transparent;
        color: ${({ theme }) => theme.colors.dark};
        img {
          transition: filter 0.3s;
        }
        &:hover img {
          filter: brightness(0) invert(1);
        }
      `}

    ${({ loading }) =>
      loading &&
      css`
        opacity: 0.7;
        cursor: wait;
        pointer-events: none;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          background: inherit;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          background-image: url(${loadingIcon});
          background-size: auto 75%;
          background-position: center;
          background-repeat: no-repeat;
        }
      `}

    &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
  }
`

Button.defaultProps = {
  red: true,
}

export const Clear = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
`

export default Button
