import type {
  Breakpoints,
  Colors,
  Fonts,
  Grid,
  Navigation,
  Header,
  Table,
  Estates,
  Container,
  Transitions,
} from 'types/theme'

export const breakpoints: Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1430,
  xxxl: 1600,
}

export const colors: Colors = {
  yellow: '#FFC10E',
  lightyellow: '#F8ECCB',
  darkyellow: '#A18F5E',
  darkyellow100: '#E4C68E',
  darkyellow200: '#FCE7AC',
  red: '#F50000',
  green: '#84CB1C',
  light: '#FEF6E2',
  dark: '#212121',
  text: '#4E4E4E',
  lighttext: '#888888',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#F1F1F1',
  gray100: '#C7C7C7',
  gray200: '#E5E5E5',
  gray300: '#7B7B7B',
  gray400: '#CBCBCB',
  darkgray: '#707070',
  available: '#3ba440',
  reserved: '#fcb21f',
  sold: '#e81d25',
  planned: '#FFC10E',
}

export const fonts: Fonts = {
  size: '16px',
}

export const grid: Grid = {
  gap: '1rem',
}

export const container: Container = {
  widthNav: '1920px',

  widthXL: '1760px',
  width: '1200px',

  widthSlimXL: '1520px',
  widthSlim: '1100px',

  widthNarrowXL: '820px',
  widthNarrow: '760px',

  paddingMD: '2rem',
  padding: '1.35rem',
}

const navigation: Navigation = {
  height: '90px',
  heightMD: '60px',
}

export const header: Header = {
  mapWidth: '450px',
}

const table: Table = {
  maxWidth: '1300px',
  firstRow: '140px',
}

const estates: Estates = {
  cardWidth: '300px',
  cardHeight: '500px',
  cardWidthXXL: '400px',
  cardHeightXXL: '700px',
  cardGap: '20px',
}

const transitions: Transitions = {
  slide: '0.6s',
}

const theme = {
  breakpoints,
  colors,
  fonts,
  grid,
  navigation,
  header,
  table,
  container,
  estates,
  transitions,
}

export default theme
